import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById( 'root' ),
)

serviceWorker.register()
